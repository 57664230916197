<template>
  <div class="cover-page container-fluid">
    <div class="row clearfix">
      <div class="col-md-offset-1 col-md-10 clearfix cover-title">
        <div class="pull-left">
          <ul class="nav nav-tabs">
            <li :class="[activeTab === tab.name ? 'active' : '', 'text-small']" @click="setActiveTab(tab)"
                v-for="(tab, index) in tabList" :key="index" role="presentation">
              <a href="javascript:;" :class="[activeTab === tab.name ? 'text-primary' : 'text-muted']">
                <i :class="['fa', `fa-${tab.icon}`]"></i>
                {{tab.label}}
                <span class="label label-success" v-if="tab.new">new</span>
              </a>
            </li>
          </ul>
        </div>
        <button class="btn primary pull-right" @click="$router.push({name: 'preview', query: $route.query})">
          <i class="fa fa-eye"></i>
          <span>{{$t('cover.tabSave')}}</span>
        </button>
      </div>
    </div>
    <div class="row" v-if="dataLoaded">
      <div class="col-sm-12 col-md-5 col-md-offset-1 cover-wrap">
        <swiper :options="swiperOption" ref="mySwiper" v-if="activeTab === 'insert'">
          <!-- slides -->
          <swiper-slide v-for="(page, index) in insertPages" :key="index">
            <cover-holder :src="page" :height="coverHeight" />
          </swiper-slide>
          <!-- Optional controls -->
          <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
          <div class="swiper-button-next swiper-button-black" slot="button-next"></div>
        </swiper>
        <cover-holder :src="currentImage" :height="coverHeight" v-else/>
        <div class="text-center mt-1" v-show="activeTab === 'cover'">
          <a :href="previewImage" target="_blank">
            <button class="btn default-ghost">
              查看原圖
              <i class="fa fa-external-link"></i>
            </button>
          </a>
        </div>
        <div class="text-center text-mini text-muted mt-1" v-show="activeTab === 'typeset'">
          圖片樣例僅供參考
        </div>
      </div>
      <div class="col-sm-12 col-md-5 col-md-offset-1 hidden-xs bg-white">
        <template v-if="activeTab === 'cover'">
          <div class="form-group">
            <label>{{$t('cover.labelBookTitle')}}（{{$t('cover.labelMaxCount', {count: 25})}}）</label>
            <input type="text" :value="design.title" @change="changeTitle" class="form-control" maxlength="25">
          </div>
          <div class="form-group">
            <label>{{$t('cover.labelBookAuthor')}}（{{$t('cover.labelMaxCount', {count: 15})}}）</label>
            <input type="text" :value="design.author" @change="changeAuthor" class="form-control" maxlength="15">
          </div>
          <div class="form-group" v-show="colorfulCovers.test(design.template)">
            <label >{{$t('cover.labelCoverSelect')}}</label>
            <color-list :coverColor="design.cover.color" @change="changeColor"></color-list>
          </div>
          <div class="form-group">
            <div class="clearfix">
              <div class="pull-left">
                <label>{{$t('cover.labelCoverType')}}</label>
                <div class="text-mini text-muted">點擊選擇封面</div>
              </div>
              <div class="pull-right" v-show="plainCover.indexOf(design.template) === -1">
                <uploader @change="uploadCover" fileId="cover-input" :class="{disabled: uploading}">
                  <button class="btn secondary" :class="{disabled: uploading}">
                    <template v-if="uploading">
                      <i class="fa fa-spinner fa-spin"></i>
                      上傳中...
                    </template>
                    <template v-else>
                      <i class="fa fa-photo"></i>
                      {{$t('cover.labelButtonUpload')}}
                    </template>
                  </button>
                </uploader>
              </div>
            </div>
            <cover-type :activeConfig="design.template" @change="changeCoverType" />
          </div>
        </template>
        <template v-if="activeTab === 'typeset'">
          <div class="form-group">
            <label>{{$t('cover.tabTypeset')}}</label>
            <typeset-type :typeset="design.typeset" @change="changeTypeset" />
          </div>
        </template>
        <template v-if="activeTab === 'insert'">
          <div class="form-group">
            <label>月份插頁</label>
            <insert-page :insert="design.insertPage" @change="changeInsert" />
          </div>
        </template>
      </div>
    </div>
    <div class="loading-content" v-else>
      <div class="loading-block text-center">
        <div class="loading-icon"></div>
        <p class="text-mini text-muted mt-1">
          <strong>封面加載中</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CoverType from './CoverType.vue'
import TypesetType from './TypesetType.vue'
import ColorList from './ColorList.vue'
import api from 'src/api/'
import config from 'src/app.config'
import generateCover from 'src/_utils/generateCover'
import { mapGetters } from 'vuex'
import CoverHolder from '../../../components/CoverHolder'
import InsertPage from './InsertPage'

const design = {
  author: '',
  title: '',
  typeset: 'a5-1',
  template: 'type-1', // 封面模板样式，
  cover: {
    color: '#ffffff',
    imageUrl: ''
  }
}
export default {
  name: 'cover',
  components: { CoverType, TypesetType, ColorList, CoverHolder, InsertPage },
  data () {
    return {
      design: Object.assign({}, design),
      coverHeight: 141.1,
      activeTab: 'cover',
      showActionSheet: false,
      currentContent: {},
      uploading: false,
      colorfulCovers: config.colorfulCovers,
      plainCover: config.plainCover,
      swiperOption: {
        spaceBetween: 50
      },
      chapters: null,
      dataLoaded: false,
      templates: {
        'a5-1': 'https://static.ipastbook.com/assets/images/A5-1-simple.jpg',
        'a5-2': 'https://static.ipastbook.com/assets/images/A5-2-simple.jpg',
        'a4-1': 'https://static.ipastbook.com/assets/images/A4-1-simple.jpg',
        'a4-2': 'https://static.ipastbook.com/assets/images/A4-1-child.jpg'
      }
    }
  },
  created () {
    let { bid, item } = this.$route.query
    bid = this.$route.params.bid || bid
    item && (this.activeTab = item)
    if (!bid) {
      this.$toast({
        message: this.$t('cover.toastCoverError')
      })
      return
    }
    this.fetchBook(bid)
    // this.fetchCoverDesign(bid)
  },
  async beforeRouteLeave (to, from, next) {
    await this.saveCoverDesign(false)
    next()
  },
  computed: {
    ...mapGetters(['language']),
    tabList () {
      return [
        { name: 'cover', label: '封面', icon: 'book' },
        { name: 'typeset', label: '版式', icon: 'th', new: true },
        { name: 'insert', label: '插頁', icon: 'bookmark' }
      ]
    },
    swiper () {
      return this.$refs.mySwiper.swiper
    },
    currentImage () {
      if (!this.dataLoaded) return ''
      if (this.activeTab !== 'typeset') {
        return generateCover({ design: this.design, chapters: this.chapters })
      }
      return this.templates[this.design.typeset] || ''
    },
    insertPages () {
      const pages = []
      if (!this.dataLoaded) return pages
      const year = (new Date()).getFullYear()
      const codeName = this.design.insertPage || 'en'
      for (let i = 1; i <= 12; i++) {
        pages.push(`https://canvas.xinshu.me/generate/insert-${codeName}?year=${year}&month=${i}`)
      }
      return pages
    },
    previewImage () {
      return generateCover({ design: this.design, chapters: this.chapters, size: 1920 })
    }
  },
  methods: {
    fetchBook (bid) {
      return api.fetchBook(bid).then(data => {
        if (!data || data.errors) {
          this.$toast({
            message: this.$t('cover.toastCoverLoadFailed'),
            position: 'bottom'
          })
          return
        }
        let { currentChapters, design, typeset } = data
        if (typeset.includes('tweet')) {
          typeset = typeset.includes('waterfall') ? 'a5-1' : 'a5-2'
        }

        Object.keys(config.replaceColor).map((item) => {
          if (item === design.cover.color.toLowerCase()) {
            design.cover.color = config.replaceColor[item]
          }
        })
        design.typeset = typeset
        this.chapters = currentChapters
        this.design = design
        this.dataLoaded = true
        return Promise.resolve(design)
      })
    },
    fetchCoverDesign (bid) {
      return api.fetchCoverDesign(bid).then(data => {
        if (!data || data.errors) {
          this.$toast({
            message: this.$t('cover.toastCoverLoadFailed'),
            position: 'bottom'
          })
          return
        }
        if (data.typeset.includes('tweet')) {
          data.typeset = data.typeset.includes('waterfall') ? 'a5-1' : 'a5-2'
        }
        Object.keys(config.replaceColor).map((item) => {
          if (item === data.cover.color.toLowerCase()) {
            data.cover.color = config.replaceColor[item]
          }
        })
        this.design = data
        this.dataLoaded = true
        return Promise.resolve(this.design)
      })
    },
    changeTitle (e) {
      this.design.title = e.target.value
      this.saveCoverDesign()
    },
    changeAuthor (e) {
      this.design.author = e.target.value
      this.saveCoverDesign()
    },
    changeCoverType (name) {
      this.design.template = name
      this.saveCoverDesign()
    },
    changeTypeset (name) {
      this.design.typeset = name
      if (/a4-2$/i.test(name)) {
        // A4 童趣版强制选择插页
        this.design.insertPage = 'child'
      }
      this.saveCoverDesign()
    },
    changeColor (color) {
      this.design.cover.color = color
      this.saveCoverDesign()
    },
    changeInsert (insert) {
      this.design.insertPage = insert
      this.saveCoverDesign()
    },
    uploadCover (files) {
      let file = files[0]
      const formData = new window.FormData()
      if (!file) {
        return
      }
      file = file.source || file // 判断是否经过 FileReader 处理
      const { size } = file
      if (size >= 1000 * 1000 * 10) { // 提醒过大图片尺寸
        this.$toast({
          message: '圖片尺寸過大，请耐心等待'
        })
      }
      this.uploading = true
      formData.append('image', file)
      api.uploadImage(formData).then(data => {
        let { origin, hostname } = window.location
        this.uploading = false
        if (!data || data.errors) {
          this.$toast({
            message: '上傳錯誤，請重新上傳'
          })
          return
        }

        if (hostname === 'localhost' || /\d/g.test(origin)) {
          origin = 'https://ipastbook.com'
        } else if (/test|beta/.test(hostname)) {
          origin = 'https://test.ipastbook.com'
        }
        data.image = /http?s:/.test(data.image) ? data.image : `${origin}${data.image}`
        this.design.cover.imageUrl = data.image
      }).catch(e => {
        this.uploading = false
      })
    },
    saveCoverDesign (notify = true) {
      if ((this.design.author || this.design.title) && this.design.template && this.design.cover.color) {
        const bid = this.$route.params.bid || this.$route.query.bid
        return api.updateCoverDesign(bid, this.design).then(data => {
          if (!data || data.errors) {
            this.$toast({
              message: this.$t('cover.toastCoverUpdateError'),
              position: 'bottom'
            })
          }
          if (notify) {
            this.$toast({
              message: this.$t('text.modified'),
              position: 'bottom',
              duration: 1000
            })
          }
        })
      }
    },
    setActiveTab (item) {
      this.activeTab = item.name
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, item: item.name } })
    },
    updateDesign (content) {
      this.showActionSheet = false
      switch (content.type) {
        case 'template':
          this.design.template = content.content.template
          this.design.cover.color = content.content.color
          break
        case 'typeset':
          this.design.typeset = content.content.content
          break
        case 'title':
          this.design.title = content.content.title
          this.design.author = content.content.author
          break
      }
      this.saveCoverDesign()
    }
  }
}
</script>

<style lang="scss">

  @import '../../../styles/layout/Cover';
</style>
