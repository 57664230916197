<template>
  <div class="insert-page">
    <div class="insert-page-list">
      <div class="insert-page-item" v-for="(page, index) in insertPages" :key="index">
        <cover-holder :src="page.image" :height="coverHeight"
                      :selected="insert === page.name"
                      @click="$emit('change', page.name)" />
      </div>
    </div>
  </div>
</template>

<script>
import CoverHolder from '../../../components/CoverHolder'
export default {
  props: {
    insert: {
      type: String,
      default: 'simple'
    }
  },
  components: { CoverHolder },
  data: () => ({
    insertPages: [
      {
        name: 'simple',
        image: 'https://canvas.xinshu.me/generate/insert-en',
        label: '簡約'
      },
      {
        name: 'child',
        image: 'https://canvas.xinshu.me/generate/insert-child',
        label: '童趣'
      }
    ],
    coverHeight: 141.1
  }),
  computed: {},
  created () {},
  methods: {}
}
</script>

<style lang="scss">
.insert-page {
  &-list {
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    flex: 25%;
    padding: 5px;
  }
}
</style>
